<template>
  <dv-border-box-10 style="width:49.5%;height:23%;">
    <div class="tilte">
      <div style="color:#fff">{{ title }}</div>
      <div class="flex" style="color: #07dbff;">
        <img
          src="https://shiankuaixian.oss-cn-shenzhen.aliyuncs.com/user/20210810/20210810031743-547.png"
          class="image"
          alt=""
        />
        <span>市场当月交易走势</span>
        <span style=" font-size: 0.16rem;">（交易量/交易额）</span>
      </div>
    </div>
    <div class="echarts" :key="title" ref="echarts"></div>
  </dv-border-box-10>
</template>

<script>
export default {
  name: 'indexLine',
  props: ['title', 'array'],
  data () {
    return {}
  },
  created () {},
  watch: {
    array (val) {
      if (val) {
        this.getEcharts()
      }
    }
  },
  methods: {
    getEcharts () {
      const MyEcharts2 = this.$echarts.init(this.$refs.echarts)
      const day = []
      const count = []
      const price = []
      this.array.forEach(item => {
        day.push(item.day * 1 + '日')
        count.push(item.count)
        price.push(item.price)
      })
      const option2 = {
        backgroundColor: '',
        legend: {
          orient: 'vertical',
          icon: 'rect', //形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 16, // 设置宽度
          itemHeight: 16, // 设置高度
          itemGap: 20, // 设置间距
          top: 'center',
          data: ['交易量', '交易额'],
          right: '1%',
          textStyle: {
            //文字样式
            color: '#ffffff',
            fontSize: '16'
          }
        },
        // x轴
        xAxis: {
          data: day,
          boundaryGap: false, // 坐标轴右侧是否留白, 默认是true
          axisLabel: {
            textStyle: {
              color: '#ffffff',
              fontSize: '12'
            }
          },
          axisTick: {
            show: false
          }
        },
        // 坐标系内绘图网格
        grid: {
          left: '2%',
          right: '10%',
          bottom: '9%',
          top: '4%',
          containLabel: true
        },
        // 提示
        tooltip: {
          trigger: 'axis', // 坐标轴触发
          axisPointer: {
            // 坐标轴指示器配置项
            type: 'cross' //  十字准星指示器。表示启用两个正交的轴的 axisPointer
          }
        },
        // y轴
        yAxis: {
          axisLabel: {
            textStyle: {
              color: '#ffffff'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#0e2edc94'],
              width: 1,
              type: 'solid'
            }
          }
        },
        series: [
          {
            name: '交易量',
            type: 'line',
            smooth: true, // 线条光滑
            itemStyle: {
              color: '#FFEA00'
            },
            areaStyle: {
              color: '#FFEA00',
              // 区域填充
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(223, 238, 20)'
                  },
                  {
                    offset: 0.4,
                    color: 'rgb(223, 238, 20,0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(223, 238, 20,0)'
                  }
                ])
              }
            },
            data: count
          },
          {
            name: '交易额',
            smooth: true,
            type: 'line',
            itemStyle: {
              color: '#32A9FE'
            },
            areaStyle: {
              // 区域填充
              color: '#32A9FE',
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(80,141,255)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(56,155,255,0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(38,197,254,0.00)'
                  }
                ])
              }
            },
            data: price
          }
        ]
      }
      MyEcharts2.setOption(option2)
    }
  },
  mounted () {
    this.getEcharts()
  }
}
</script>
<style lang="less" scoped>
/deep/ .border-box-content {
  padding: 0.1rem;
  box-sizing: border-box;
}
.tilte {
  width: 100%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.echarts {
  width: 100%;
  height: calc(100% - 0.35rem);
}
</style>
