<template>
  <div class="Echartsline">
    <lines
      v-for="item in list"
      :key="item.id"
      :title="item.comp_name"
      :array="item.array"
    ></lines>
  </div>
</template>

<script>
import { monthTradingTrend } from '@api/index'
import line from '@comp/Echartsline/line.vue'
export default {
  name: 'Echartsline',
  data () {
    return {
      list: []
    }
  },
  components: {
    lines: line
  },
  created () {
    this.monthTradingTrend()
  },
  methods: {
    monthTradingTrend () {
      monthTradingTrend().then(res => {
        this.list = res.data
      })
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.Echartsline {
  width: 100%;
  height: calc(100% - 0.7rem);
  padding: 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
